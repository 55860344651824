import WsProvider from './provider/WSProvider'

var serviceName = "webservis";
var moduleName = "blog";

var haberService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,

    haberTanimlama(bkategoriID, baslik, altBaslik, icerik, sunucudanEklenenResimler, resim, resimUzunluk, video, resimKonum, menuKonum, galeriKonum, durum, seoView, seoBaslik, seoIcerik, baslamaTarihi, bitisTarihi) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            bkategoriID: bkategoriID,
            baslik: baslik,
            altBaslik: altBaslik,
            icerik: icerik,
            seoBaslik: seoBaslik,
            seoIcerik: seoIcerik,
            sunucudanEklenenResimler: sunucudanEklenenResimler,
            resim: resim,
            resimUzunluk: resimUzunluk,
            video: video,
            resimKonum: resimKonum,
            menuKonum: menuKonum,
            galeriKonum: galeriKonum,
            durum: durum,
            seoKategori: 1,
            seoSorguTuru: 1,
            seoDurum: 1,
            seoSayac: 0,
            baslamaTarihi: baslamaTarihi,
            bitisTarihi: bitisTarihi,
            serviceName: moduleName,
            methodName: "blogEkle"
        }

        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    haberListe(durum, baslangic, limit) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            durum,
            baslangic,
            limit,
            serviceName: moduleName,
            methodName: "blogListele"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    haberDurumSayisi(durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            durum,
            serviceName: moduleName,
            methodName: "blogDurumSayisi"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    haberCount() {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "blogSayisi"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    hKategoriListele(durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            data: {
                token: userDataBGSurec,
                durum: durum,
                serviceName: moduleName,
                methodName: "hKategoriListele"
            }
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    haberDetay(haberId, link) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            blogID: haberId,
            link: link,
            serviceName: moduleName,
            methodName: "blogDetay"
        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;

    },

    haberDurumDegis(haberID, durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            blogID: haberID,
            durum,
            serviceName: moduleName,
            methodName: "blogAktifPasifEt"
        };
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },

    haberGuncelleme(haberID, bkategoriID, baslik, altBaslik, icerik, sunucudanEklenenResimler, resim, resimUzunluk, video, resimKonum, menuKonum, galeriKonum, baslamaTarihi, bitisTarihi, durum, seoBaslik, seoIcerik) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            blogID: haberID,
            bkategoriID,
            baslik: baslik,
            altBaslik: altBaslik,
            icerik: icerik,
            sunucudanEklenenResimler: sunucudanEklenenResimler,
            resim: resim,
            resimUzunluk: resimUzunluk,
            video: video,
            resimKonum: resimKonum,
            menuKonum: menuKonum,
            galeriKonum: galeriKonum,
            baslamaTarihi: baslamaTarihi,
            bitisTarihi: bitisTarihi,
            seoKategori: 1,
            seoSorguTuru: 1,
            seoSayac: 0,
            durum: durum,
            seoBaslik,
            seoIcerik,
            methodName: "blogGuncelle",
            serviceName: moduleName
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },




}

export default haberService