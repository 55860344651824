<template>
    <div class="page-table haberTanim mainDiv listPage" id="affix-container">
        <div class="card-base baslikBg animated fadeInUp">
            {{$t("src.views.apps.haberler.liste.title")}}

            <div class="tanimlamaButon">
                <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
                    <el-button v-on:click="refreshPage()" type="text" icon="el-icon-refresh" style="margin-left: 15px !important"></el-button>
                </el-tooltip>

                <el-tooltip :content='$t("src.views.apps.genel.yeniKaydet")' :open-delay="500" placement="bottom">
                    <el-button v-on:click="routeBulten('Blog Tanımla')" type="text" icon="el-icon-plus" style="margin-left: 15px !important"></el-button>
                </el-tooltip>
            </div>
        </div>

        <div class="page-header card-base card-shadow--medium animated fadeInUp" 
        :lazy="true"
        v-loading="loading || loadingState"
        :element-loading-text='loading ? $t("src.views.apps.haberler.liste.listeLoading") : $t("src.views.apps.haberler.liste.durumLoading")'
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 1)">
            <el-row class="mt-0 mb-10">
                <el-col :lg="16" :md="16" :sm="24" :xs="24">
                    <div>
                        <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9"  text-color="gray">
                            <el-radio-button label="1">
                                <i class="mdi mdi-bullhorn mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                                <transition name="slide-fade">
                                    <label v-if="selectIcon==='1'">{{$t("src.views.apps.haberler.liste.yayinda")}}</label>
                                </transition>
                            </el-radio-button>
                            <el-radio-button label="0">
                                <i class="mdi mdi-bullhorn mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                                <transition name="slide-fade">
                                    <label v-if="selectIcon==='0'">{{$t("src.views.apps.haberler.liste.pasif")}}</label>
                                </transition>
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </el-col>

                <el-col :lg="8" :md="8" :sm="24" :xs="24">
                    <el-col :lg="24" :md="24" :sm="24" :xs="24">
                        <ul class="islemler">
                            <!-- <li class="detay">
                                <i class="mdi mdi-account"></i> Oluşturan
                            </li> -->
                            <li class="goruntule">
                                <i class="mdi mdi-redo-variant"></i> {{$t("src.views.apps.genel.goruntule")}}
                            </li>
                            <li class="duzenle">
                                <i class="mdi mdi-pen"></i> {{$t("src.views.apps.genel.duzenle")}}
                            </li>
                            <li v-if="selectIcon === '1'" class="sil">
                                <i class="mdi mdi-trash-can"></i> {{$t("src.views.apps.genel.sil")}}
                            </li>
                            <li v-else class="aktif">
                                <i class="mdi mdi-play"></i> {{$t("src.views.apps.genel.yayin")}}
                            </li>
                        </ul>
                    </el-col>
                </el-col>
            </el-row>

            <el-table :data="haberlerList.filter(data => !search.baslik || data.baslik.toLowerCase().includes(search.baslik.toLowerCase()) )" ref="filterTable"
                border
                stripe
                style="width: 100%">
                <el-table-column
                    :label='$t("src.views.apps.genel.sira")'
                    type="index"
                    :index="indexMethod"
                    width="50">
                </el-table-column>
                <el-table-column
                    :label='$t("src.views.apps.haberler.liste.table.haberID")'
                    prop="haberID"
                    width="90">
                </el-table-column>
                <el-table-column
                    :label='$t("src.views.apps.haberler.liste.table.resim")'
                    width="100">
                    <template slot-scope="scope">
                        <el-popover
                                transition="el-fade-in-linear"
                                placement="left"
                                width="auto"
                                trigger="hover">
                            <img v-if="scope.row.resim" :src="imagepath+scope.row.resim" :alt="scope.row.baslik">
                            <i style="font-size: 24px; cursor: pointer; color: #337ab7" slot="reference" class="el-icon-picture"></i>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="baslik"
                    :label='$t("src.views.apps.haberler.liste.table.baslik")'
                    width="auto">
                </el-table-column>
                <el-table-column
                    sortable
                    prop="basTarih"
                    :label='$t("src.views.apps.haberler.liste.table.yayinTarihi")'
                    width="180">
                </el-table-column>
                <el-table-column
                    :label='$t("src.views.apps.genel.islem")'
                    align="right"
                    width="250">
                    <template slot-scope="scope">
                        <el-tooltip :content='$t("src.views.apps.haberler.liste.haberdetay")' :open-delay="500" placement="bottom">
                            <el-button v-on:click="yeniSekmedeAc(path+scope.row.link)" type="text" class="ml-15" style="color: #606266" icon="mdi mdi-redo-variant mdi-24px">
                            </el-button>
                        </el-tooltip>

                        <el-tooltip :content='$t("src.views.apps.haberler.liste.haberlerduzenle")' :open-delay="500" placement="bottom">
                            <el-button
                                v-if="root || yetkiList.haberGuncelle"
                                v-on:click="routeBulten('Blog Düzenle',scope.row)"
                                type="text"
                                style="color: #f7ba2a; margin-left: 25px !important"
                                icon="mdi mdi-pen mdi-24px">
                            </el-button>
                        </el-tooltip>

                        <el-tooltip :content='$t("src.views.apps.genel.yayindanKaldir")' :open-delay="500" placement="bottom">
                            <el-button
                                v-if="selectIcon==='1' && (root || yetkiList.haberSil)"
                                @click="haberDurumChange(scope.row, 0)"
                                size="mini"
                                class="buttonDel"
                                style="margin-left: 15px !important"
                                type="text"
                                icon="mdi mdi-trash-can mdi-24px">
                            </el-button>
                        </el-tooltip>

                        <el-tooltip :content='$t("src.views.apps.sayfa.liste.yayinaAl")' :open-delay="500" placement="bottom">
                            <el-button
                                v-if="selectIcon==='0' && (root || yetkiList.haberSil)"
                                @click="haberDurumChange(scope.row, 1)"
                                type="text"
                                class="buttonAktif"
                                style="margin-left: 15px !important"
                                icon="mdi mdi-play mdi-24px">
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            
            <el-pagination
                background
                layout="sizes,prev, pager, next, jumper, total"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="pageChange"
                :page-sizes="[10, 20, 50, 100]"
                :current-page.sync="page"
                :next-click.sync="page"
                :prev-click.sync="page"
                @next-click="pageChange"
                @prev-click="pageChange">
            </el-pagination>
        </div>

    </div>
</template>

<script>
    import haberlerService from '../../../WSProvider/HaberService'
    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from '@/components/event-bus'

    var moment = require('moment');

    export default {
        name: "HaberlerListe",
        mounted() {
            let self = this;
            if (localStorage.getItem("userDataBGSurec")) {
                self.root = true;
            }
            
            this.selectIcon = '1',
            EventBus.$on('haberList', function (bool) {
                if (bool) {
                    self.getHaberList();
                    self.getHaberCount();
                }
            });

            this.getHaberList()
            this.getHaberCount();
        },
        data() {
            return {
                selectIcon: '1',
                radio: '1',
                root: false,
                yetkiList: {
                    haberGuncelle: false,
                    haberSil: false,
                },
                path: haberlerService.path,
                search: {
                    baslik: ''
                },
                imagepath: haberlerService.imagePath,
                index: '',

                page: 0,
                sizes: 10,
                postPage: 0,
                total: null,

                haberlerList: [],
                loading: false,
                loadingState: false,
            }
        },
        methods: {
            refreshPage(){
                this.loading = false;
                this.loadingState = false;
                this.page = 1;
                this.postPage = 0;
                this.getHaberList()
                this.getHaberCount();
            },

            yeniSekmedeAc(link){
                window.open(link)
            },

            handleChange(event) {
                this.page = 1;
                this.postPage = 0;
                this.selectIcon = event;
                this.getHaberList()
                this.getHaberCount();
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },

            getHaberList() {
                this.loading = true;
                this.loadingState = false;
                haberlerService.haberListe(this.selectIcon, this.postPage, this.sizes).then((response) => {
                    if(response.status == 200){
                        localStorage.setItem("userDataBGSurec", response.token)
                        response.data.forEach(function (e) {
                            e.haberBaslamaTarih = moment(e.haberBaslamaTarih).format('DD-MM-YYYY HH:mm:ss');
                            e.haberBitisTarih = moment(e.haberBitisTarih).format('DD-MM-YYYY HH:mm:ss');
                        })
                        this.haberlerList = response.data
                    }
                    this.loading = false;
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }else if(error.status == 404){
                            this.haberlerList = [];
                        }
                        else notification.Status("errorMsg", this, error.errMsg)
                    }else{
                        //notification.Status(503, this)
                    }
                    this.loading = false;
                })
            },

            getHaberCount() {
                haberlerService.haberDurumSayisi(this.selectIcon).then((response) => {
                    if(response.status == 200){
                        localStorage.setItem("userDataBGSurec", response.token)
                        this.total = response.data
                    }
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }else if(error.status == 404){
                            this.total = 0;
                        }
                    }else{
                        //notification.Status(503, this)
                    }
                })
            },

            routeBulten(name, scope) {
                if (scope) {
                    this.$store.commit('changeHaberData', scope);
                }
                functions.routeSayfa(name, this);
            },

            haberDurumChange(selection, durum) {
                this.$confirm("Haberi güncellemek istediğinize emin misiniz?", this.$t("src.views.apps.genel.uyari"), {
                    confirmButtonText: this.$t("src.views.apps.genel.yes"),
                    cancelButtonText: this.$t("src.views.apps.genel.no"),
                    type: 'warning'
                }).then(() => {
                    try{
                        this.loadingState = true;
                        haberlerService.haberDurumDegis(selection.haberID, durum).then(response => {
                            if(response.status == 200){
                                localStorage.setItem("userDataBGSurec", response.token)
                                this.getHaberList()
                                this.getHaberCount();
                            }
                            this.loadingState = false;
                            notification.Status("success", this, response.msg)
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status === 401){
                                    notification.Status(150, this);
                                }
                                else notification.Status("errorMsg", this, error.errMsg)
                            }else{
                                //notification.Status(503, this)
                            }
                            this.loadingState = false;
                        })
                    }catch(e){
                        // console.log({e})
                    }
                }).catch(e => {})
            },

            handleSizeChange(val) {
                this.sizes = val;
                this.getHaberList()
                this.getHaberCount();
            },

            pageChange(val) {
                this.postPage = (val - 1) * this.sizes;
                 this.getHaberList()
                this.getHaberCount();
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },

            indexMethod(index){
                return ((this.page - 1)* 10) + index + 1;
            },
        }
    }

</script>

<style lang="scss">
    .el-pagination {
        margin-top: 20px;
        float: right;
    }

    .slide-fade-enter-active {
        transition: all .3s ease;
    }


    @media (min-width: 768px) {
        .el-radio-button--mini .el-radio-button__inner {
            padding: 0 10px !important;
            font-size: 11px;
            border-radius: 0;
        }
    }


    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }
</style>
<style lang="scss" scoped>
    .el-pagination {
        margin-top: 20px;
        float: right;
    }
    .dangerbtn {
        color: #ec205f;
        position: absolute;
        bottom: -12px;
        right: -24px;
    }

    .successbtn {
        color: #13ce66;
        position: absolute;
        top: -12px;
        right: -24px;
    }

    .el-button--mini {
        padding: 0px 15px !important;
        height: 25px !important;
    }
</style>
